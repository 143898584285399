import React from "react";
import Edon from "../assets/ec_hs.jpg";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img
          className="w-[300px] mx-auto my-4 rounded-full"
          src={Edon}
          alt="/"
        />
        <div className="flex flex-col justify-center">
          <p className="text-[#db5461] font-bold ">
            GRUNDARE, VD OCH CLOUDSPECIALIST
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Edon Cakolli
          </h1>
          <p>
            Jag är Edon, en erfaren IT-konsult med fokus på infrastruktur,
            molntjänster, projektledning och ledande roller. Med skräddarsydda
            lösningar optimerar jag IT-infrastrukturer och leder framgångsrika
            projekt. Låt mig hjälpa dig att maximera din IT-effektivitet och nå
            dina affärsmål. Kontakta mig för samarbete och framgång inom IT.
          </p>
          <div className="flex flex-row">
            <div className="flex flex-row">
              <BsFillTelephoneFill className="mt-1" />
              <p> +46 70 00 950 90</p>
            </div>
            <div className="flex flex-row pl-5">
              <MdEmail className="mt-1" />
              <p>edon@cakolli.se</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
