import React from "react";

const Newsletter = () => {
  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
        <div className="lg:col-span-2 my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Vi kan mycket mer än det.
          </h1>
          <p>Knappa in din e-post så hör vi av oss!</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col sm:flex-row items-center justify-between w-full">
            <input
              className="p-3 flex w-full rounded-md text-black hidden"
              type="email"
              placeholder="Ange e-post"
            />

            <button
              onClick={() => (window.location = "mailto:edon@cakolli.se")}
              className="bg-[#db5461] text-[#171738] rounded-md font-bold w-[240px] ml-4 my-6 px-6 py-3"
            >
              Kontakta oss!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
