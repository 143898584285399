import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

import CCLogo from "../assets/CAKOLLI CONSULTING.png";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        <img
          className="w-[300px] mx-auto my-4 rounded-full"
          src={CCLogo}
          alt="/"
        />
        <div className="flex justify-between md:w-[75%] my-6 hidden">
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
          <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} />
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          <p className="py-4 pr-8">Firma Edon Cakolli</p>
          <p className="py-4 pr-8">Godkänd för F-skatt</p>
        </div>
        <div className="">
          <h1 className="w-full text-3xl font-bold text-[#db5461]">
            CAKOLLI CONSULTING.
          </h1>
          <p className="text-[#db5461] font-bold p-2 text-s">
            IT ska inte vara krångligt.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
