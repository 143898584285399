import React from "react";
import Typed from "react-typed";
import CCLogo from "../assets/CAKOLLI CONSULTING.png";

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full mx-auto text-center flex flex-col justify-center pb-20 pt-20">
        <img
          className="w-[300px] mx-auto my-4 rounded-full"
          src={CCLogo}
          alt="/"
        />
        <p className="text-[#db5461] font-bold p-2 text-xl">
          IT SKA INTE VARA KRÅNGLIGT
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Ta hjälp av oss.
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
            Vi hjälper er med
          </p>
          <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["IT-support", "implementering", "projektledning"]}
            typeSpeed={120}
            backSpeed={60}
            loop
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500">
          Spetskompetens inom Microsoft 365 och Azure som tar er IT-miljö till
          nästa nivå!
        </p>
      </div>
    </div>
  );
};

export default Hero;
