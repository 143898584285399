import React from "react";

const Navbar = () => {
  return (
    <div className="flex justify-between items-center h-24 pl-24 mx-auto px bg-black bg-opacity-40 text-white">
      <h1 className="w-full text-3xl font-bold text-[#db5461]">
        CAKOLLI CONSULTING.
      </h1>
    </div>
  );
};

export default Navbar;
