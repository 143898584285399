import React from "react";
import M365Logo from "../assets/m365svg.svg";
import AzureLogo from "../assets/azure.svg";
import { BiLogoDevTo } from "react-icons/bi";

const Cards = () => {
  return (
    <div className="w-full py-[5rem] px-4 bg-white">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img
            className="w-20 mx-auto mt-[-3rem] bg-white"
            src={AzureLogo}
            alt="/"
          />
          <h2 className="text-2xl font-bold text-center py-8">Azure</h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Migrering</p>
            <p className="py-2 border-b mx-8">DevOps</p>
            <p className="py-2 border-b mx-8">Azure Virtual Desktop</p>
            <p className="py-2 border-b mx-8">SecOps</p>
            <p className="py-2 border-b mx-8">Infrastruktur</p>
          </div>
        </div>
        <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
          <img
            className="w-20 mx-auto mt-[-3rem] bg-transparent"
            src={M365Logo}
            alt="/"
          />
          <h2 className="text-2xl font-bold text-center py-8">Microsoft 365</h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Migrering</p>
            <p className="py-2 border-b mx-8">Support</p>
            <p className="py-2 border-b mx-8">Rådgivning</p>
            <p className="py-2 border-b mx-8">Säkerhetsanalys</p>
            <p className="py-2 border-b mx-8">Moderna arbetsplatser</p>
          </div>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <BiLogoDevTo
            size={110}
            color={"#171738"}
            className="mx-auto mt-[-3rem]"
          />

          <h2 className="text-2xl font-bold text-center py-2">
            Utveckling och marknadsföring
          </h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Grafisk design</p>
            <p className="py-2 border-b mx-8">Mjukvaruutveckling</p>
            <p className="py-2 border-b mx-8">Optimering</p>
            <p className="py-2 border-b mx-8">Robotic Process Automation</p>
            <p className="py-2 border-b mx-8">Mockups</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
